body {
  color: rgb(33, 33, 33);
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-weight: 200;
  font-size: 1.0625rem;
  line-height: 1.3;
  -moz-osx-font-smoothing: grayscale;
  background-color: #b6b7ba;
}

strong {
  font-weight: 400;
}

#outerwrapper {
  display: block;
  margin: 0px auto;
  width: 100%;
  padding: 0px;
}

#wrapper {
  max-width: 800px;
  width: 100%;
  margin-top: 30px;
  display: block;
  margin: 0px auto;
  width: 100%;
  background-color: #ededed;
}

#header {
  background-color: #41454b;
  padding: 10px;
}

#header h2 {
  color: #ffffff;
  text-align: center;
  font-size: 50px;
  font-weight: 400;
}

#header img {
  margin: 0px auto;
  display: block;
}

.opt-out-success,
#wrapper form {
  padding: 20px;
}

input[type="text"] {
  margin-top: 30px;
  background-color: #ffffff;
  color: var(--palette-text-primary);
  border-radius: 0.25rem;
  font-family: Segoe UI, Segoe WP, Tahoma, Arial, sans-serif;
  min-height: 2rem;
  font-size: 1rem;
  line-height: 1.25rem;
  width: 100%;
  max-width: 735px;
  border-width: 0px 0px 0.125rem;
  border-bottom-width: 0.125rem;
  border-style: none none solid;
  border-bottom-style: solid;
  border-color: currentcolor currentcolor transparent;
  border-bottom-color: transparent;
  outline: none;
  padding: 0.375rem 0.75rem;
}

input[type="submit"] {
  background-color: #41454b;
  padding: 10px;
  color: #ffffff;
  border-radius: 0.25rem;
  padding: 0px 1.25rem;
  min-width: 5.375rem;
  min-height: 2.5rem;
  line-height: 1.375rem;
  font-weight: 600;
  font-size: 0.875rem;
  border: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.requiredStar {
  color: #bc2f32;
  font-weight: 400;
}